import React from "react";
import LooseDiamondsCountry from "../../components/FrontStatic/WholesaleLooseDiamonds/Country";
import {graphql} from "gatsby";
import {connect} from "react-redux";
import SEO from "../../components/SEO";

const CountryComponent = (props) =>
    <React.Fragment>
        <SEO {...(props?.data?.seo?.frontmatter || {})}/>
        <LooseDiamondsCountry
            {...props}
            fields={props?.data}
            country="Israel"
        />
    </React.Fragment>;

const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(CountryComponent);
export const query = graphql`
 fragment IsraelFragment on MarkdownRemark {
      frontmatter {
      title
      description
    }
  }
  query IsraelPageQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "wld-is-seo"}}) {
        frontmatter {
            title
            description
        }
  }
  enIsrael: markdownRemark(frontmatter: {templateKey: {eq: "en-wld-is"}}) {
    ...IsraelFragment
  }
  deIsrael: markdownRemark(frontmatter: {templateKey: {eq: "de-wld-is"}}) {
    ...IsraelFragment
  }
  esIsrael: markdownRemark(frontmatter: {templateKey: {eq: "es-wld-is"}}) {
    ...IsraelFragment
  }
  frIsrael: markdownRemark(frontmatter: {templateKey: {eq: "fr-wld-is"}}) {
    ...IsraelFragment
  }
  heIsrael: markdownRemark(frontmatter: {templateKey: {eq: "he-wld-is"}}) {
    ...IsraelFragment
  }
  itIsrael: markdownRemark(frontmatter: {templateKey: {eq: "it-wld-is"}}) {
    ...IsraelFragment
  }
  jaIsrael: markdownRemark(frontmatter: {templateKey: {eq: "ja-wld-is"}}) {
    ...IsraelFragment
  }
  zhIsrael: markdownRemark(frontmatter: {templateKey: {eq: "zh-wld-is"}}) {
    ...IsraelFragment
  }
 }
`;
